<template>
  <div>
    <v-card class="rounded-lg" elevation="0">
      <section class="header-table">
        <v-text-field
          v-model="search"
          class="rounded-lg"
          label="Digite o nome do usuário..."
          single-line
          hide-details
          outlined
          dense
        ></v-text-field>
        <div class="space"></div>
        <v-btn
          @click="$router.go(-1)"
          class="rounded-lg"
          elevation="0"
          color="black"
        >
          <v-icon small class="mr-2" color="#FFFFFF">
            mdi-keyboard-backspace
          </v-icon>
          <span class="textBtn"> Voltar </span>
        </v-btn>
        <div class="space"></div>
        <v-btn
          @click="clearFilter()"
          class="rounded-lg"
          elevation="0"
          color="black"
        >
          <span class="textBtn"> Limpar </span>
        </v-btn>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="success"
          @click="searchUser"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-magnify </v-icon>
          <span class="textBtn"> Pesquisar Usuário </span>
        </v-btn>
        <div class="space"></div>
        <v-btn
          class="rounded-lg"
          elevation="0"
          color="primary"
          @click="dialog = true"
        >
          <v-icon small class="mr-2" color="#FFFFFF"> mdi-plus </v-icon>
          <span class="textBtn"> Criar Usuário </span>
        </v-btn>
      </section>

      <v-data-table
        :headers="headers"
        :items="usuarios"
        :search="search"
        :footer-props="{
          'items-per-page-text': 'Usuários por Página',
          'items-per-page-options': [10, 20, 50, 100, -1],
        }"
        :page="page"
        :pageCount="numberOfPages"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :server-items-length="totalUsers"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <Crud
            :resource="item"
            v-on:eventname="crudUser"
            v-on:reload_parente="indoDataTable"
          />
        </template>
        <template v-slot:[`item.cpf`]="{ item }">
          {{ item.cpf | VMask("###.###.###-##") }}
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" width="600px" persistent>
        <v-card class="create-user-dialog rounded-lg">
          <div class="banner">
            <h4>Criar Usuário</h4>
          </div>

          <AlertError
            class="mb-5"
            v-model="displayError"
            :resource="displayError"
          />

          <v-form @submit.prevent="create" ref="form">
            <v-text-field
              class="rounded-lg"
              label="Nome"
              placeholder="Nome completo"
              v-model="name"
              :rules="nameRules"
              dense
            ></v-text-field>
            <v-text-field
              class="rounded-lg"
              label="E-mail"
              placeholder="E-mail"
              v-model="email"
              :rules="emailRules"
              dense
            ></v-text-field>

            <v-text-field
              class="rounded-lg"
              label="CPF"
              placeholder="Cpf"
              v-model="cpf"
              :rules="cpfRules"
              dense
            ></v-text-field>

            <v-select
              clearable
              dense
              v-model="id_unity_user"
              :items="list_unities"
              label="Selecione a unidade..."
              item-text="unity_name"
              item-value="id"
              :rules="unityRules"
            ></v-select>

            <v-select
              clearable
              dense
              v-model="id_profile_user"
              :items="list_profiles"
              label="Selecione a perfil..."
              item-text="profile_name"
              item-value="id"
              :rules="profileRules"
            ></v-select>

            <v-text-field
              class="rounded-lg"
              label="Senha"
              placeholder="Senha do Usuário"
              type="password"
              v-model="password"
              dense
              :rules="passwordRules"
            ></v-text-field>

            <v-card-actions class="cardFooter">
              <v-spacer></v-spacer>

              <v-btn
                class="rounded-lg"
                color="#e74c3c"
                text
                elevation="0"
                @click="clearModal()"
              >
                <span>Cancelar</span>
              </v-btn>

              <v-btn
                class="rounded-lg"
                elevation="0"
                color="#27ae60"
                type="submit"
              >
                <span class="textBtn">Enviar dados</span>
                <v-icon color="#FFFFFF" small class="ml-2"
                  >mdi-arrow-right</v-icon
                >
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@/axios";
import Crud from "./CrudUsers-comp.vue";
import AlertError from "../alerts/Error-component.vue";
export default {
  data() {
    return {
      dialog: false,
      search: "",
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "name_user",
        },
        { text: "CPF", value: "cpf" },
        { text: "E-mail", value: "email" },
        { text: "Perfil", value: "profile.profile_name" },
        { text: "Unidade", value: "unity.unity_name" },
        { text: "Ações", value: "actions", sortable: false },
      ],

      nameRules: [(v) => !!v || "O campo é obrigatório"],
      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+/.test(v) || "O e-mail não é válido.",
      ],
      cpfRules: [(v) => !!v || "O campo é obrigatório"],
      unityRules: [(v) => !!v || "O campo é obrigatório"],
      profileRules: [(v) => !!v || "O campo é obrigatório"],
      passwordRules: [(v) => !!v || "O campo é obrigatório"],

      displayError: {
        display: false,
        message: [],
      },

      page: 1,
      totalUsers: 0,
      numberOfPages: 0,
      passengers: [],
      loading: true,
      options: {},
      sortBy: "name",

      usuarios: [],
      message: "",
      snackbar: false,
      loadingBTN: false,
      exibirMensagem: false,
      messageError: "",

      list_profiles: [],
      list_unities: [],
      name: "",
      cpf: "",
      email: "",
      id_profile_user: "",
      id_unity_user: "",
      password: "",
    };
  },
  components: {
    Crud,
    AlertError,
  },

  watch: {
    sortBy: {
      handler() {},
    },
    options: {
      handler() {
        this.indoDataTable();
      },
    },
    deep: true,
  },
  //FIXME - METHODS
  methods: {
    clearFilter() {
      this.search = "";
    },
    clearModal() {
      this.name = "";
      this.email = "";
      this.id_profile_user = "";
      this.id_unity_user = "";
      this.password = "";
      this.dialog = false
    },
    getAllUnities() {
      axios.get("/unity").then((response) => {
        this.list_unities = response.data;
      });
    },
    getAllProfiles() {
      axios.get("/profile").then((response) => {
        this.list_profiles = response.data;
      });
    },

    searchUser() {
      let data = new Object();
      data.per_page = this.options.itemsPerPage;
      axios
        .get(
          `/users/list_user?per_page=${data.per_page}&name_user=${this.search}`
        )
        .then((response) => {
          this.loading = false;
          this.usuarios = response.data.data;
          this.totalUsers = response.data.total;
          this.page = response.data.current_page;
        });
    },
    indoDataTable() {
      this.loading = true;
      let data = new Object();
      data.per_page = this.options.itemsPerPage;
      this.usuarios = [];

      axios
        .get(
          `/users/list_user?per_page=${this.options.itemsPerPage}&page=${this.options.page}`
        )
        .then((response) => {
          this.loading = false;
          this.usuarios = response.data.data;
          this.totalUsers = response.data.total;
          this.numberOfPages = response.data.last_page;
          this.page = response.data.current_page;
        });
    },

    create() {
      if (this.$refs.form.validate() == true) {
        const newCPF = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
        const dados = new Object();
        dados.name_user = this.name;
        dados.cpf = newCPF;
        dados.email = this.email;
        dados.id_profile_user = this.id_profile_user;
        dados.id_unity_user = this.id_unity_user;
        dados.password = this.password;

        axios
          .post(`/users`, dados)
          .then((response) => {
            this.message = response.data.message;

            this.$swal({
              position: "top-end",
              icon: "success",
              title: "Registro concluído com sucesso!",
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              this.clearModal();
              this.indoDataTable();
            });
          })
          .catch((error) => {
            var errors = "";
            Object.values(error).map((value) => {
              errors += `${value} `;
            });
            this.$swal({
              position: "top-end",
              icon: "error",
              title: errors,
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              this.dialog = false;
            });
          });
      }
    },

    crudUser(resource) {
      if (resource == "") {
        this.indoDataTable();
      } else {
        axios.delete(`/users/${resource}`).then((response) => {
          this.indoDataTable();
          this.message = response.data.message;
          this.snackbar = true;
        });
      }
    },
  },

  mounted() {
    this.getAllProfiles();
    this.getAllUnities();
    this.indoDataTable();
  },
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #27ae60,
    #1abc9c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner > h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
