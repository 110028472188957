<template>
  <div class="tableOptions">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small class="mr-2 iconeEditar" @click="openEditModal">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon class="iconeDeletar" small @click="dialogAPagar = true">
            mdi-delete
          </v-icon>
        </span>
      </template>
      <span>Apagar</span>
    </v-tooltip>

    <v-dialog
      v-model="dialogEditar"
      width="600px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-edit">
          <h4>Editar Informações</h4>
        </div>

        

        <div class="space"></div>

        <v-form
          @submit.prevent="edit"
          v-model="valid"
          lazy-validation
          ref="form"
        >
          <v-text-field
            label="Nome"
            placeholder="Placeholder"
            outlined
            v-model="resource.name_user"
            :rules="nameRules"
            dense
          ></v-text-field>
          <v-text-field
            label="E-mail"
            placeholder="Placeholder"
            outlined
            v-model="resource.email"
            :rules="emailRules"
            dense
          ></v-text-field>
          <v-text-field
            label="CPF"
            placeholder="Placeholder"
            outlined
            v-mask="'###.###.###-##'"
            v-model="resource.cpf"
            :rules="cpfRules"
            dense
          ></v-text-field>

          <v-select
            clearable
            outlined
            dense
            v-model="unity_user"
            :items="list_unities"
            label="Selecione a unidade..."
            item-text="unity_name"
            item-value="id"
            return-object
            :rules="unityRules"
          ></v-select>

          <v-select
            clearable
            outlined
            dense            
            v-model="profile_user"
            :items="list_profiles"
            label="Selecione a perfil..."
            item-text="profile_name"
            item-value="id"
            return-object
            :rules="profileRules"
          ></v-select>

          <v-card-actions class="cardFooter">
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg btn"
              color="red"
              text
              elevation="0"
              @click="cancelEdit"
            >
              <span>Cancelar</span>
            </v-btn>

            <v-btn
              class="rounded-lg"
              color="success"
              elevation="0"
              type="submit"
            >
              <span class="textBtn">Enviar dados</span>
              <v-icon small class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAPagar"
      width="400px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-delete">
          <h4>Deletar Usuário</h4>
        </div>

        <div class="textInfo">
          <h6 class="textDelete">
            Deseja deletar <span class="userName">{{ resource.name }}</span> dos
            usuários cadastrados?
          </h6>
          <h6 class="alertText">(Essa ação não pode ser revertida)</h6>
        </div>

        <v-card-actions class="cardFooter">
          <v-btn
            class="rounded-lg btn"
            color="red"
            text
            elevation="0"
            @click="dialogAPagar = false"
          >
            <span>Cancelar</span>
          </v-btn>

          <v-btn
            class="rounded-lg"
            color="#27ae60"
            elevation="0"
            type="submit"
            @click="deletar"
          >
            <span class="textBtn">Confirmar</span>
            <v-icon color="#FFFFFF" small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      list_unities: [],
      list_profiles: [],

      unity_user: {
        id: this.resource.unity.id,
        unity_name: this.resource.unity.unity_name,
      },
      profile_user: {
        id: this.resource.profile.id,
        profile_name: this.resource.profile.profile_name,
      },
      //kinship: ,
      valid: true,
      nameRules: [(v) => !!v || "O campo é obrigatório"],
      emailRules: [
        (v) => !!v || "O campo é obrigatório",
        (v) => /.+@.+/.test(v) || "O campo e-mail não válido.",
      ],
      cpfRules: [(v) => !!v || "O campo é obrigatório"],
      unityRules: [(v) => !!v || "O campo é obrigatório"],
      profileRules: [(v) => !!v || "O campo é obrigatório"],
      dialogEditar: false,
      dialogAPagar: false,
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },
    };
  },

  props: {
    resource: {
      type: Object,
    },
  },

  components: {
    
  },  

  methods: {
    openEditModal(){
      this.dialogEditar = true;
      this.getAllProfiles();
    this.getAllUnities();
    },
    edit() {
      if (this.$refs.form.validate() == true) {
        const newCPF = this.resource.cpf.replace(/[^a-zA-Z0-9]/g, "");
        const dados = new Object();
        dados.id = this.resource.id;
        dados.name_user = this.resource.name_user;
        dados.cpf = newCPF;
        dados.email = this.resource.email;
        dados.id_profile_user = this.profile_user.id;
        dados.id_unity_user = this.unity_user.id;        
      
        axios
          .put(`/users/${dados.id}`, dados)
          .then((response) => {
            this.$swal({
              position: "top-end",
              icon: "success",
              title: response.data.msg,
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              this.dialogEditar = false;
              this.$emit("reload_parente");
            });
          })          
      }
    },

    getAllUnities() {
      axios.get("/unity").then((response) => {
        this.list_unities = response.data;
      });
    },

    getAllProfiles() {
      axios.get("/profile").then((response) => {
        this.list_profiles = response.data;            
      });
    },
    cancelEdit() {
      this.dialogEditar = false;
    },

    deletar() {
      this.dialogAPagar = false;
      this.$emit("eventname", this.resource.id);
    },
  },
};
</script>

<style scoped>
.space {
  height: 10px;
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}
</style>
